<template>
  <form class="container mt-5" @submit.prevent="uploadNote">
    <div class="mb-3">
      <label for="title" class="form-label">Title</label>
      <input type="text" class="form-control" id="title" v-model="title">
    </div>
    <div class="mb-3">
      <label for="textBody" class="form-label">Note</label>
      <textarea type="text" class="form-control" id="textBody" v-model="textBody"/>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UploadNote',
  data() {
    return {
      title: "",
      textBody: "",
    };
  },
  methods: {
    async uploadNote() {
      if (!this.title || !this.textBody) {
        alert("Please fill in all fields.");
        return;
      }

      const formData = new FormData();
      formData.append('title', this.title);
      formData.append('text_body', this.textBody);

      try {
        const response = await axios.post('api/post_note', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        alert('Note uploaded successfully!');
      } catch (error) {
        console.error(error);
        alert('An error occurred while uploading the Note.');
      }
    }
  }
}
</script>