<template>
  <form class="container mt-5" @submit.prevent="uploadImage">
    <div class="mb-3">
      <label for="description" class="form-label">Description</label>
      <input type="text" class="form-control" id="description" v-model="description">
    </div>
    <div class="mb-3">
      <label for="imageField" class="form-label">Image</label>
      <input type="file" accept="image/*" class="form-control" id="imageField" @change="onFileChange">
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UploadImage',
  data() {
    return {
      description: '',
      image: null,
    };
  },
  methods: {
    onFileChange(event) {
      this.image = event.target.files[0];
    },
    async uploadImage() {
      if (!this.image || !this.description) {
        alert("Please fill in all fields.");
        return;
      }

      const formData = new FormData();
      formData.append('description', this.description);
      formData.append('image_url', this.image);

      try {
        const response = await axios.post('api/post_image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error(error);
        alert('An error occurred while uploading the image.');
      }
    }
  }
}
</script>

<style>
/* Add any custom styles here */
</style>
