import axios from 'axios';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

axios.defaults.baseURL = "https://api.ifuckingloveyou.life"

createApp(App).use(store).use(router, axios).use(bootstrap).mount('#app')
