<script>
export default {
  name: "Note",
  props: {
    title: "",
    textBody: "",
  }
}
</script>

<template>
  <div class="card mb-5" style="width: 20rem">
    <div v-text="title" class="card-header"></div>
    <div class="card-body">
      <blockquote class="blockquote mb-0 text-start">
        <p v-text="textBody"></p>
      </blockquote>
    </div>
    <div class="card-footer">
      <p class="blockquote-footer customFont m-0 text-end">Your <cite title="Source Title">Lover</cite></p>
    </div>
  </div>
</template>