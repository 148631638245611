<script>
import axios from "axios";
import NoteComponent from "@/components/NoteComponent.vue";

export default {
  name: "Letters",
  data() {
    return {
      notes: []
    }
  },
  methods: {
    getLetters() {
      axios.get("api/get_notes")
          .then(response => {
            this.notes = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  components: {
    NoteComponent
  },
  mounted() {
    document.title = "Love Letters"
    this.getLetters()
  }
}
</script>

<template>
  <div class="container p-5">
    <h1 class="display-4 customFont mb-5 mt-5">Letters to my <span style="color: #a33e59">Lover</span> M</h1>
  </div>
  <div class="container" v-for="note in notes" :key="note.id">
    <NoteComponent
      v-bind:title="note.title"
      v-bind:text-body="note.text_body"
    />
  </div>
</template>