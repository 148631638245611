<template>
  <div class="container customFont">
    <h1 class="display-5">Some of your favorite <span style="color: #33963d ">things</span>:</h1>

    <p class="display-6">- Tulips 🌷</p>
    <p class="display-6">- Peonies 🌸</p>
    <p class="display-6">- Jelly 🪼</p>
    <p class="display-6">- Legos 🏗️</p>
    <p class="display-6">- Romance Novels 📖</p>
    <p class="display-6">- Potatoes 🥔</p>
    <p class="display-6">- Me 🦹‍♂️</p>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = "The Love of My Life"
  }
}
</script>