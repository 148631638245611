<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light customFont" style="padding: 10px">
    <div class="container-fluid gap-4">
      <a class="navbar-brand" href="#">My Love</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav gap-4 me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/you">You</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/us">Us 💍</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/notes">Love Notes 📓</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Navbar -->
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&family=Playwrite+SK:wght@100..400&display=swap');

.customFont{
  font-family: "Playwrite SK", cursive;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
