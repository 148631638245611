<script>
export default {
  props: {
    description: "",
    imgSource: "",
  }
}
</script>

<template>
<!--  <div class="card border border-1 border-opacity-25 border-black shadow-lg p-3 mb-5 bg-body rounded-4 bg-danger-subtle" style="width: 18rem;">-->
<!--    <img v-bind:src="imgSource"-->
<!--         class="card-img-top shadow-lg bg-body rounded-4 border border-1 border-opacity-50 border-black"-->
<!--         alt="..."-->
<!--    >-->
<!--    <div class="card-body">-->
<!--      <p v-text="description" class="card-text text-dark fw-bold"></p>-->
<!--    </div>-->
<!--  </div>-->
  <div class="card shadow-lg pt-1 px-1 mb-5 rounded-3" style="width: 18rem; background-color: #f25cc5">
    <img v-bind:src="imgSource"
         class="card-img-top shadow-lg"
         alt="..."
    >
    <div class="card-body">
      <p v-text="description" class="card-text text-white fw-bold"></p>
    </div>
  </div>
</template>
