<template>
  <div class="container" style="padding: 20px;">
    <h1 class="display-4 customFont">Hi Baby !</h1>
    <hr>
    <h1 class="display-6 customFont">I <b style="color: #de576c">love</b> you so fucking much!</h1>
    <p class="display-6">🐧</p>
    <button type="button" class="btn btn-dark" @click="getReason()"><span class="customFont">Why do I love <span style="color: #b5315b">YOU</span></span></button>
    <div class="container">
      <blockquote class="blockquote customFont" style="margin-bottom: 0">
        <p v-text="reason"></p>
      </blockquote>
      <figcaption v-if="reason" class="blockquote-footer customFont">
        Your <cite title="Source Title" style="color: #de576c">Lover</cite>
      </figcaption>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showReason: false,
      reason: ""
    }
  },
  methods: {
    getReason: async function () {
      axios
          .get("/api/get_reason")
          .then(response => {
            this.reason = response.data.reason + " ❤️"
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  mounted() {
    document.title = "Home"
  }
}
</script>

<style>
  .container{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  hr{
    width: 100%;
  }

  button{
    width: fit-content;
  }
</style>
