<script>
import ImgComponent from "@/components/ImgComponent.vue";
import axios from "axios";

export default {
  components: {
    ImgComponent
  },
  data() {
    return {
          images: []
    }
  },
  methods: {
    async getImages() {
      await axios.get('/api/get_images')
          .then(response => {
            this.images = response.data.map(image => {
              // Ensure the URL is absolute
              return {
                ...image,
                get_absolute_url: image.get_absolute_url.startsWith('http')
                    ? image.get_absolute_url
                    : `https://${image.get_absolute_url}`
              };
            });
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  mounted() {
    this.getImages()
    document.title = "Us 💍"
  }
}
</script>

<template>
  <header class="mb-5 mt-5">
    <div class="container">
      <h1 class="display-3 customFont">Us</h1>
    </div>
  </header>
  <main>
    <div class="container" v-for="image in images" :key="image.id">
      <ImgComponent
        v-bind:img-source="image.get_absolute_url"
        v-bind:description="image.description"
      />
    </div>
  </main>
</template>

<style>

</style>