import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HerView from '../views/HerView.vue'
import UsView from "@/views/UsView.vue"
import PostImage from "@/views/PostImage.vue";
import NotesView from "@/views/NotesView.vue";
import PostNote from "@/views/PostNote.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/you',
    name: 'you',
    component: HerView
  },
  {
    path: '/us',
    name: 'us',
    component: UsView
  },
  {
    path: '/upload',
    name: 'upload',
    component: PostImage
  },
  {
    path: '/upload',
    name: 'upload',
    component: PostImage
  },
  {
    path: '/notes',
    name: 'notes',
    component: NotesView
  },
  {
    path: '/upload_note',
    name: 'uploadNote',
    component: PostNote
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
